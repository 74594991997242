@import url('../../assets/styles/common-style.css');

/* Landing Page */
.landing-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.margin_top {
  margin-top: 20px;
}

.dpadding {
  padding: 0px 4px;
}

.form-selections {
  width: 250px;
}

.pdf-sections {
  position: relative;
}

.report-wrapper {
  width: 100%;
  margin: 0 auto;
}

.report-content-sections {
  width: 90%;
  margin: 0 auto;
}

/* Header Logo */
.header-logo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 71px;
}

.header-logo .logo-icon {
  width: 170px;
  height: 30px;
}

.makeStyles-button-1 {
  margin: 0px;
}

.sown_map_heading .input-min-text {
  font-size: 16px;
  font-weight: 500;
}

.sown_map_heading {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.footer-logo {
  display: none;
  align-items: center;
  justify-content: flex-start;
}

.logo-icon {
  width: 130px;
  height: 35px;
}

.logo-icon img {
  width: 100%;
  height: 100%;
}

.report-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-sections {
  height: calc(100vh - 65px);
  position: relative;
}

.report-bg {
  height: calc(100vh - 65px);
  width: calc(100vw - 148px);
}

.report-bg-img {
  height: 100%;
  width: 100%;
}

.report-bg-img img {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.report-seasons {
  position: absolute;
  top: 32%;
  left: 6%;
  font-size: 28px;
  font-family: 'Arial';
  color: #000;
  font-weight: 600;
}

.report-seasons .input-text {
  font-size: 28px;
  font-family: 'Arial';
}

.report-seasons label {
  display: inline-block;
}

.district_published {
  position: absolute;
  bottom: 4%;
  left: 4%;
}

.district_published li:first-child {
  background-color: #2394c2;
  color: #fff;
  margin-bottom: 10px;
  font-size: 24px;
  font-family: 'Arial';
}

.district_published li:last-child {
  background-color: #fff;
  color: #4b0082;
  font-size: 24px;
  font-family: 'Arial';
}

.district_published li:first-child .input-text {
  font-family: 'Arial';
  font-size: 24px;
}

.district_published li:last-child .input-text {
  color: #4b0082;
  font-size: 24px;
  font-family: 'Arial';
}

.district_published li {
  padding: 10px 10px 5px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 10px;
  font-family: 'Arial';
}

.report-seasons span .input-text,
.report-seasons label .input-text {
  width: 100%;
  font-size: 28px;
  font-family: 'Arial';
  color: #000;
}

.abbreviations {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.input-text {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'vag-round-std-thin';
  border: 0px;
  background-color: transparent;
}

.table-content-title {
  text-align: center;
  display: block;
  font-size: 22px;
  line-height: 20px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
}

.grographical-distribution h2 {
  font-size: 22px;
  margin-left: 40px;
}

.dynamic-text-area {
  display: list-item;
  margin-left: 1em;
  outline: none;
  margin-left: 95px;
}

.dynamic-text-area div {
  display: list-item;
}

.table-content-heading {
  margin-left: 20px;
}

.common-img-resolutions.width-350.rainfalltrend {
  width: 70%;
  margin-left: 15%;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.table-content-list li a {
  color: #000;
  font-size: 18px;
  display: block;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 10px;
}

.common-heading {
  font-size: 20px;
  font-weight: 600;
}

.common-para.left {
  text-indent: 0;
}
.common-para.chm {
  font-size: 16px;
  font-weight: 400;
  font-family: 'vag-round-std-thin';
}
.input-min-text.chm,
.input-min-text.intro {
  font-size: 16px;
  font-weight: 400;
  font-family: 'vag-round-std-thin';
  line-height: 20px;
}
.common-heading,
.table-content-list li a label {
  cursor: pointer;
}

/* Tittle Content */
.table-content-list li a {
  display: flex;
}

.table-content-list li a .title {
  order: 1;
}

.table-content-list li a .chapter {
  order: 3;
  display: none;
}

.table-content-list li a::after {
  display: none;
  /* table content dotted line code */
  /* background-image: radial-gradient(circle, currentcolor 1px, transparent 1.5px); */ 
  background-position: bottom;
  background-size: 5px 5px;
  background-repeat: space no-repeat;
  content: '';
  flex-grow: 1;
  height: 1em;
  order: 2;
}

.common-sub-heading {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.common-min-heading .input-min-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  font-family: 'vag-round-std-thin';
}

.common-min-heading {
  font-size: 18px;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.common-para {
  /* text-indent: 50px; */
  font-size: 16px;
  text-align: justify;
  margin-bottom: 10px;
  line-height: 20px;
}

.text-area {
  text-align: center;
  margin-bottom: 10px;
}

.text-area textarea {
  resize: none;
  width: 650px;
  min-height: 40px;
  border: 2px solid #e2dfdf;
  border-radius: 5px;
  font-family: 'vag-round-std-thin';
  width: 100%;
}

.formula {
  text-align: center;
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.common-img-resolutions {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.ndi-img {
  margin-top: 20px;
  width: 60%;
}

.common-img-resolutions img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.crop-identify {
  margin-bottom: 10px;
}

.crop-identify .common-img-resolutions {
  width: 60%;
}

.figures {
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  display: block;
  margin: 10px 0px;
  font-weight: 600;
}

.field-photographs-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.field-photographs-list li {
  flex-basis: 40%;
  margin-bottom: 20px;
}

.annexure {
  margin-bottom: 25px;
}

/* NDVI */
.ndvi-images,
.ndwi-images {
  flex-wrap: wrap;
  display: flex;
}

.ndvi-images li,
.ndwi-images li {
  flex-basis: 33%;
  margin-bottom: 10px;
}

.export {
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.export-pdf {
  margin-bottom: 10px;
}

.export-pdf,
.export-doc {
  background-color: #272b61;
  padding: 10px;
  border-radius: 19px;
  cursor: pointer;
}

.export-doc img,
.export-pdf img {
  cursor: pointer;
}

.back-btn {
  position: absolute;
}

.page-loader-sections {
  display: flex;
  margin: 0px;
  height: 100%;
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 10em;
  overflow: show;
  margin: auto;
  top: -150px;
  left: 0;
  bottom: 0;
  right: 0;
}

.field-imgs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.image-item {
  flex-basis: 45%;
  margin-top: 50px;
}

.image-item textarea {
  width: 80%;
}

.upload-img {
  border: 2px solid #000;
  border-radius: 6px;
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
}

.upload-img img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.img-sections {
  position: relative;
}

.image-item__btn-wrapper {
  display: none;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000c7;
}

.img-sections:hover .img-overlay {
  opacity: 1;
}

.hover-icon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.hover-icon img {
  width: 100%;
  height: 100%;
}

.image-item__btn-wrapper {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  color: #696969;
  opacity: 0.4;
}

/* photographs-card */
.photographs-card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.photographs-card li {
  flex-basis: 40%;
  margin-top: 10px;
}

.photographs-card li .text-area textarea {
  width: 80%;
}

.field-img {
  position: relative;
  border: 2px dashed #dadce0;
  border-radius: 6px;
  width: 100%;
  height: 350px;
  margin-bottom: 20px;
}

.field-img label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  color: #696969;
  opacity: 0.4;
}

.field-img .img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000c7;
}

.field-img:hover .img-overlay {
  opacity: 1;
}

.hover-icon .upload-img {
  color: #fff;
  opacity: 1;
}

.img-preview {
  width: 100%;
  height: 100%;
}

.img-preview .preview {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.upload-img input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 0rem;
}

.methodology_logo {
  width: 50%;
  height: 100%;
  padding: 20px 0px;
  margin: 0 auto;
}

.methodology_logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.annexure-table {
  margin-bottom: 20px;
}

/* Loader */
.loader-container {
  display: flex;
  margin: 0px;
  height: 100vh;
  background: #edf3f3;
}

.loader {
  height: 200px;
  width: 200px;
  margin: auto;
}

.loader > div {
  height: inherit;
  width: inherit;
  position: absolute;
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader > div > div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #272b61;
  position: absolute;
  top: 0%;
  right: 50%;
  transform: translate(50%, 0%);
}

.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.loader > div:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  50% {
  }

  100% {
    transform: rotate(360deg);
  }
}

.photographs-card .field-card:nth-child(5n),
.photographs-card li:nth-child(6n) {
  page-break-before: always;
}

.document-text {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-size: 20px;
  font-weight: 500;
}
.common-img-resolutions.weather {
  height: 250px;
}
.common-img-resolutions.rainfall {
  height: 250px;
}
.common-img-resolutions.crop-sowing {
  height: 60%;
  width: 80%;
}
.input-min-text.exhibit {
  width: 20%;
  font-size: 16px;
  font-weight: 600;
  font-family: 'vag-round-std-thin';
  line-height: 18px;
}
.upload-remove button:first-child{
  margin-right: 10px;
}

.overall_input{
  font-size: 16px;
  font-weight: 500;
  width: 260px;
}

/* @print */
@media print {
  .overall_input{
    width: 380px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    border:none;
    text-align: center;
  }
  .common-heading {
    font-size: 28px !important;
    font-weight: 600 !important;
  }

  .number{
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .common-sub-heading,
  .common-para.chm {
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .common-para {
    font-size: 24px !important;
    line-height: 28px !important;
    text-align: justify;
    margin-bottom: 10px;
  }
  .formula {
    font-size: 24px !important;
    margin-bottom: 10px !important;
  }
  .figures {
    font-size: 20px !important;
  }

  .abbreviations {
    font-size: 24px !important;
  }
  .table-content-title,
  .table-content-list li a {
    font-size: 24px !important;
    font-weight: 700 !important;
  }
  .input-min-text.exhibit {
    font-weight: 500;
    border: none;
    outline: none;
    font-weight: 24px;
  }
  .district_published li:first-child {
    background-color: #2394c2;
    color: #fff;
    margin-bottom: 10px;
    font-size: 40px !important;
    font-family: 'Arial' !important;
  }
  .district_published li:first-child .input-text {
    font-family: 'Arial' !important;
    font-size: 40px !important;
  }

  .district_published li:last-child {
    background-color: transparent;
    color: #fff;
    font-size: 38px !important;
    font-family: 'Arial' !important;
  }

  .district_published li:last-child .input-text {
    color: #fff;
    font-size: 38px !important;
    font-family: 'Arial' !important;
  }
  .report-seasons {
    top: 30%;
  }

  .report-seasons .input-text {
    font-size: 60px !important;
    font-family: 'Arial' !important;
  }

  .image-item {
    margin-top: 100px !important;
  }

  .upload-img {
    height: 450px !important;
  }

  .btn_at_print {
    display: none;
  }

  .report-published input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .text-area textarea {
    border: transparent;
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .report-wrapper {
    position: relative;
  }

  body {
    counter-reset: page;
  }

  .sown_map_heading .input-min-text {
    border: none;
    font-size: 24px;
  }
  .common-min-heading {
    font-size: 24px !important;
  }
  .common-min-heading .input-min-text {
    border: none;
    font-weight: 500;
    font-size: 24px !important;
  }

  .page-break {
    margin-top: 100px;
    page-break-before: always;
  }

  .page-break-top {
    margin-top: 200px;
  }

  .nobottom {
    position: relative;
    height: calc(100% - 150px);
  }

  .number {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .number:after {
    counter-increment: page;
    content: counter(page);
  }

  .tittlebottom {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .methodology_logo {
    width: 90%;
    height: 40%;
  }

  .chm-img .page-break-after.nobottom:first-child {
    height: calc(100% - 220px);
  }

  .chm-img .page-break-after:not(:first-child) {
    page-break-before: always;
    margin-top: 100px;
  }

  .header-logo {
    position: fixed;
    top: 0;
    width: 90%;
  }

  .footer-logo {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 90%;
    margin-bottom: 30px;
  }

  .ndvi-images .ndvi_ndwi,
  .ndwi-images .ndvi_ndwi {
    flex-basis: 50%;
  }

  .ndvi-images .common-img-resolutions,
  .ndwi-images .common-img-resolutions {
    height: 100%;
    width: 100%;
    object-fit: none;
  }

  .crop-identify .common-img-resolutions,
  .dis-wise .common-img-resolutions,
  .crop-cal .common-img-resolutions {
    height: 10% !important;
  }

  .pieChat {
    height: 100%;
  }

  .common-img-resolutions.ndi-img {
    margin-top: 30px !important;
    height: 60px !important;
  }

  .common-img-resolutions.ndvi-chart,
  .common-img-resolutions.ndwi-chart {
    height: 650px;
  }

  .common-img-resolutions {
    height: 850px;
  }

  .common-img-resolutions.acreage_sowing,
  .common-img-resolutions.crop-growth {
    width: 50%;
    height: 30%;
  }

  .common-img-resolutions.acreage_sowing img,
  .common-img-resolutions.crop-growth img {
    object-fit: fill;
  }

  .common-img-resolutions.weather {
    height: 350px;
  }

  .fortnight {
    height: 450px !important;
  }

  .common-img-resolutions.crop-sown-map {
    height: 400px;
  }

  .common-img-resolutions.crop-sown-map-2 {
    height: 80%;
    width: 80%;
  }

  .common-img-resolutions.crop-sown-map-2 img {
    object-fit: fill;
  }
  .crop-name {
    text-align: center;
  }
  /* .common-img-resolutions.crop-sowing {
    height: 500px !important;
  } */

  .common-img-resolutions.crop-sowing {
    height: 550px;
  }
  .common-img-resolutions.crop-sowing.table {
    height: auto;
  }
  .common-img-resolutions.crop-sowing.table img {
    max-height: 550px;
    min-height: 200px;
    height: auto;
    width: 100%;
  }

  .common-img-resolutions.sowing-tabel {
    height: 100px;
    width: 500px;
  }

  .common-img-resolutions.ground_truth {
    width: 60%;
    height: 70%;
  }

  .common-img-resolutions.ground_truth img {
    object-fit: fill;
  }

  .common-img-resolutions.crop-sowing img {
    object-fit: fill;
  }

  .common-img-resolutions.width-350 {
    height: 350px;
  }

  .common-img-resolutions.width-350.rainfalltrend {
    height: 550px !important;
    margin-left: 10% !important;
  }

  .crop-list .common-img-resolutions {
    height: 700px;
  }

  .report-sections {
    height: 100vh;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    z-index: 99999;
  }

  .report-bg-img {
    height: 100vh;
  }

  .report-bg {
    width: 100%;
  }

  .header-logo {
    height: 100px;
  }

  .empty-page {
    height: 100vh;
  }

  .table-content-list li a .chapter {
    order: 3;
    display: block;
  }

  .table-content-list li a::after {
    display: block;
  }

  .upload-img {
    height: 300px;
  }

  .upload-remove {
    display: none;
  }

  

  .formula,
  .figures,
  .common-min-heading,
  .common-sub-heading,
  .table-content-title,
  .common-heading,
  .table-content-list li a {
    font-weight: 500;
  }

  .feild-print {
    display: none;
  }
  .study-area {
    border: none;
    resize: none;
  }
  .common-para .input-min-text {
    border: none;
  }
  .common-para .input-min-text.intro,
  .common-para .input-min-text.chm {
    font-size: 24px !important;
    font-weight: 500;
    font-family: 'vag-round-std-thin';
    line-height: 28px !important;
  }
}

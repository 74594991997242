@font-face {
  font-family: 'poppins-regular';
  src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'vag-round-regular';
  src: url('../fonts/VAG\ Rounded\ Regular.ttf');
}

@font-face {
  font-family: 'vag-round-std-regular';
  src: url('../fonts/VAGRoundedStd-Light.ttf');
}

@font-face {
  font-family: 'vag-round-std-thin';
  src: url('../fonts/VAG\ Rounded\ Thin.ttf');
}
/* Common */
body {
  /* font-family: "poppins-regular"; */
  font-family: 'vag-round-std-thin';
  box-sizing: border-box;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: #000;
}

ul,
li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

table {
  width: 100%;
}

table {
  margin: 20px 0px;
}

table,
th,
td {
  text-align: start;
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

th {
  text-align: center;
  font-size: 18px !important;
  font-weight: 600 !important;
}

th,
td {
  padding: 5px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

textarea {
  font-family: 'vag-round-std-thin';
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
}

.top-0 {
  margin-top: 0px !important;
}

.header_input {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  border: transparent;
}

.dynamic_td td input {
  width: 100%;
  border: transparent;
  overflow: hidden;
  resize: none;
  text-align: center;
  font-size: 16px;
  font-family: 'vag-round-std-thin';
}

.dynamic_td td input:focus-visible {
  outline: none;
}
@media print {
  th {
    text-align: center;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  th,
  td {
    font-size: 24px;
    line-height: 28px;
  }
  textarea {
    font-size: 24px;
    line-height: 28px;
  }
  .dynamic_td td input {
    font-size: 24px;
  }
}

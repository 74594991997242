.login-container {
    position: relative;
    height: 100vh;
}

.login-sections {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-header {
    text-align: center;
    margin-bottom: 30px;
}

.header-satsurelogo {
    margin: 0 auto;
    width: 300px;
}

.header-satsurelogo img {
    width: 100%;
    height: 100%;
}

.login-header p {
    font-size: 20px;
    color: #282560;
}

.login-card {
    border: 1px solid #000;
    border-radius: 8px;
    width: 450px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    padding: 25px;
}

.form-group {
    position: relative;
    margin-bottom: 30px;
}

.form-group label {
    display: block;
    font-size: 18px;
    color: #282560;
    margin-bottom: 10px;
}

.form-group label sup {
    color: red;
    font-weight: 700;
}

.form-control {
    display: block;
    width: 94%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #1566b7;
    border-radius: 8px;
}

.login-button {
    text-align: center;
}

.login-button button {
    border: 1px solid #000 !important;
    font-weight: bold;
    color: #000 !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0 !important;
    width: 150px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

input:focus-visible {
    outline: none;
}

.password-eye {
    position: absolute;
    right: 10px;
    top: 50px;

}
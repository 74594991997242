.pageLoader-sections {
  display: flex;
  margin: 0px;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5); */
}

.page-loader-sections {
  display: flex;
  margin: 0px;
  height: 100%;
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 10em;
  overflow: show;
  margin: auto;
  top: -150px;
  left: 0;
  bottom: 0;
  right: 0;
}
.loader {
  height: 200px;
  width: 200px;
  margin: auto;
}
.loader > div {
  height: inherit;
  width: inherit;
  position: absolute;
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
.loader > div > div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #272b61;
  position: absolute;
  top: 0%;
  right: 50%;
  transform: translate(50%, 0%);
}
.loader > div:nth-child(2) {
  animation-delay: 0.2s;
}
.loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.loader > div:nth-child(4) {
  animation-delay: 0.6s;
}

.header-sections {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.MuiFormControl-root {
  min-width: 250px !important;
}

.logout button {
  font-size: 18px;
  border: 1px solid #000 !important;
  font-weight: bold;
  color: #156366 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: #e0e0e0 !important;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.logout {
  position: absolute;
  top: 10px;
  right: 10px;
}
